import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
//css
import "../../../styles/resNav.css";

const ResSideNav = (props) => {
    const { closeSlide } = props;

    let pathname = window.location.pathname;
    const refLink = useRef(pathname);


    return (
        <div className="resNavContainer">
            <div className="resNavHdr">
                <div className="resNavBrandingBlock">
                    <div className="resNavLogo">
                        <img src="/images/logo/invc.png" className="logo invertWhite" alt="invc logo" />
                    </div>
                    <div className="resNavAction" onClick={closeSlide}></div>
                </div>
            </div>
            <div className="resnNavBdy">
                <div className="resNavOverlfowCntnr">

                    <ul className="resNavListGroup">
                        <div className="resNavLinkSet">
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/home" activeClassName="active" className="resNavLink" onClick={closeSlide}>Home</NavLink>
                            </li>
                        </div>
                        <div className="resNavHdngBlock wrtcL">
                            <div className="resNavHdngActive wrtc" />
                            <span className="resNavHdngTxt">Web Conference</span>
                        </div>

                        <div className="resNavLinkSet">
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/invc" activeClassName="active" className="resNavLink" onClick={closeSlide}>inVC</NavLink>
                                <small className="resNavtrailingTxt">Host &amp; Room based</small>
                            </li>
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/invcplus" activeClassName="active" className="resNavLink" onClick={closeSlide}>inVC Plus</NavLink>
                                <small className="resNavtrailingTxt">SIP &amp; WebRTC</small>
                            </li>
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/invcpro" activeClassName="active" className="resNavLink" onClick={closeSlide}>inVC Pro</NavLink>
                                <small className="resNavtrailingTxt">H.323/SIP/WebRTC</small>
                            </li>
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/combo-package" activeClassName="active" className="resNavLink" onClick={closeSlide}>Combo Package</NavLink>
                                <small className="resNavtrailingTxt">inVC + inVC Plus &amp; Pro</small>
                            </li>
                        </div>



                        <div className="resNavHdngBlock vclinicL">
                            <div className="resNavHdngActive vclinic" />
                            <span className="resNavHdngTxt">Virtual clinic</span>
                        </div>
                        <div className="resNavLinkSet">
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/inclinix" activeClassName="active" className="resNavLink" onClick={closeSlide}>inClinix</NavLink>
                                <small className="resNavtrailingTxt">Host &amp; Room based</small>
                            </li>
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/plans-pricing-inclinix" activeClassName="active" className="resNavLink" onClick={closeSlide}>Plans &amp; Pricing</NavLink>
                            </li>
                        </div>


                        <div className="resNavHdngBlock vclassL">
                            <div className="resNavHdngActive vclass" />
                            <span className="resNavHdngTxt">Virtual classroom</span>
                        </div>
                        <div className="resNavLinkSet">
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/inclass" activeClassName="active" className="resNavLink" onClick={closeSlide}>inClass</NavLink>
                                {/* <small className="resNavtrailingTxt">Host &amp; Room based</small> */}
                            </li>
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/plans-pricing-inclass" activeClassName="active" className="resNavLink" onClick={closeSlide}>Plans &amp; Pricing</NavLink>
                            </li>
                        </div>


                        <div className="resNavHdngBlock wcastL">
                            <div className="resNavHdngActive wcast" />
                            <span className="resNavHdngTxt">Web Casting</span>
                        </div>
                        <div className="resNavLinkSet">
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/incast" activeClassName="active" className="resNavLink" onClick={closeSlide}>inCast</NavLink>
                                {/* <small className="resNavtrailingTxt">Host &amp; Room based</small> */}
                            </li>
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/plans-pricing-incast" activeClassName="active" className="resNavLink" onClick={closeSlide}>Plans &amp; Pricing</NavLink>
                            </li>
                        </div>



                        <div className="resNavHdngBlock apiL">
                            <div className="resNavHdngActive api" />
                            <span className="resNavHdngTxt">invc api</span>
                        </div>
                        <div className="resNavLinkSet">
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/p2p-api" activeClassName="active" className="resNavLink" onClick={closeSlide}>P2P-API</NavLink>
                                {/* <small className="resNavtrailingTxt">Host &amp; Room based</small> */}
                            </li>
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/plans-pricing-p2p-api" activeClassName="active" className="resNavLink" onClick={closeSlide}>Plans &amp; Pricing</NavLink>
                            </li>
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/multipoint-api" className={`${pathname.match("/multipoint-api") ? "resNavLink active" : "resNavLink"}`} onClick={closeSlide}>multipoint-api</NavLink>
                                {/* <small className="resNavtrailingTxt">Host &amp; Room based</small> */}
                            </li>
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/plans-pricing-multipoint-api" activeClassName="active" className="resNavLink" onClick={closeSlide}>Plans &amp; Pricing</NavLink>
                            </li>
                        </div>

                        <div className="resNavHdngBlock commL">
                            <div className="resNavHdngActive comm" />
                            <span className="resNavHdngTxt">Reach Us</span>
                        </div>
                        <div className="resNavLinkSet">
                            <li className="resNavListItem">
                                <NavLink ref={refLink} to="/contact-us" activeClassName="active" className="resNavLink" onClick={closeSlide}>Contact Us</NavLink>
                            </li>
                        </div>


                    </ul>
                </div>
            </div>
        </div>
    )
}
export default ResSideNav