import React from 'react';
import "./loader.css";

function Loader() {
    return (
        <div className="loaderComp">
            <div className="loaderBox">
                loading...
            </div>
        </div>
    )
}
export default Loader;