import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
//css
import "../../../styles/footerNav.css";

const FooterNav = (props) => {
    let pathname = window.location.pathname;
    const refLink = useRef(pathname)

    useEffect(() => {
        window.scrollTo(0, 0);
        sctollTop()
    }, []);

    const sctollTop = () => {
        window.scrollTo(0, 0)
    }
    return (
        <div className="footerNavCntnr">
            <div className="footerNavWrap">
                {/* <div className="footerLinkItem">
                    <div className="footerLinkBlock">
                        <h4 className="footerLinkHdng">Home</h4>
                        <NavLink ref={refLink} to="/home" title="home page" className={`${pathname.match("/home") ? "footerLink linkActive" : "footerLink"}`}>Home</NavLink>
                    </div>
                </div> */}
                <div className="footerLinkItem">
                    <div className="footerLinkBlock">
                        <h4 className="footerLinkHdng">web conference</h4>
                        <NavLink ref={refLink} to="/invc" title="InVC" activeClassName="linkActive" className="footerLink">inVC</NavLink>
                        <NavLink ref={refLink} to="/plans-pricing-invc" title="Buy InVC" activeClassName="linkActive" className="footerLink" >Plans &amp; Pricing</NavLink>
                        <NavLink ref={refLink} to="/invcpro" title="InVC Pro" activeClassName="linkActive" className="footerLink" >inVC Pro</NavLink>
                        <NavLink ref={refLink} to="/plans-pricing-invcpro" title="Buy inVC Pro" activeClassName="linkActive" className="footerLink" >Plans &amp; Pricing</NavLink>
                        <NavLink ref={refLink} to="/invcplus" title="InVC Plus" activeClassName="linkActive" className="footerLink" >inVC Plus</NavLink>
                        <NavLink ref={refLink} to="/plans-pricing-invcplus" title="Buy InVC Plus" activeClassName="linkActive" className="footerLink" >Plans &amp; Pricing</NavLink>
                    </div>
                </div>
                <div className="footerLinkItem">
                    <div className="footerLinkBlock">
                        <h4 className="footerLinkHdng">virtual clinic</h4>
                        <NavLink ref={refLink} to="/inclinix" title="" activeClassName="linkActive" className="footerLink">in clinics</NavLink>
                        <NavLink ref={refLink} to="/plans-pricing-inclinix" title="" activeClassName="linkActive" className="footerLink" >Plans &amp; Pricing</NavLink>
                    </div>
                </div>

                <div className="footerLinkItem">
                    <div className="footerLinkBlock">
                        <h4 className="footerLinkHdng">virtual classroom</h4>
                        <NavLink ref={refLink} to="/inclass" title="" activeClassName="linkActive" className="footerLink" >inClass</NavLink>
                        <NavLink ref={refLink} to="/plans-pricing-inclass" title="" activeClassName="linkActive" className="footerLink" >Plans &amp; Pricing</NavLink>
                    </div>
                </div>

                <div className="footerLinkItem">
                    <div className="footerLinkBlock">
                        <h4 className="footerLinkHdng">web casting</h4>
                        <NavLink ref={refLink} to="/incast" title="" activeClassName="linkActive" className="footerLink" >inCast</NavLink>
                        <NavLink ref={refLink} to="/plans-pricing-incast" title="" activeClassName="linkActive" className="footerLink" >Plans &amp; Pricing</NavLink>
                    </div>
                </div>

                <div className="footerLinkItem">
                    <div className="footerLinkBlock">
                        <h4 className="footerLinkHdng">invc api suite</h4>
                        <NavLink ref={refLink} to="/p2p-api" title="P2P API" activeClassName="linkActive" className="footerLink" >P2P-API</NavLink>
                        <NavLink ref={refLink} to="/plans-pricing-p2p-api" title="Buy P2P API" activeClassName="linkActive" className="footerLink" >Plans &amp; Pricing</NavLink>
                        <NavLink ref={refLink} to="/multipoint-api" title="Mulitpoint API" activeClassName="linkActive" className="footerLink" >Multipont-API</NavLink>
                        <NavLink ref={refLink} to="/plans-pricing-multipoint-api" title="Buy Multipoint API" activeClassName="linkActive" className="footerLink" >Buy Multipoint-API</NavLink>
                    </div>
                </div>
                <div className="footerLinkItem">
                    <div className="footerLinkBlock">
                        <h4 className="footerLinkHdng">Reach Us</h4>
                        <NavLink ref={refLink} to="/contact-us" title="InAffiliate" activeClassName="linkActive" className="footerLink" >Contact Us</NavLink>
                    </div>
                </div>
            </div>
            <div className="cpBlock">
                <div className="cpTxtBox">
                    <small className="cpSmTxt">Copyright © 2021 inVC. All rights reserved.</small>
                </div>
                <div className="cpLinkBox">
                    <ul className="cpUL">
                        <li className="cpListItem">
                            <NavLink ref={refLink} to="/terms-of-services" title="Terms Of Services" activeClassName="linkActive" className="footerLink" >Terms Of Service</NavLink>
                        </li>
                        <li className="cpListItem">
                            <NavLink ref={refLink} to="acceptable-use-policy" title="Acceptable Use Policy" activeClassName="linkActive" className="footerLink" >Acceptable Use Policy</NavLink>
                        </li>
                        <li className="cpListItem">
                            <NavLink ref={refLink} to="/privacy-policy" title="Privacy Policy" activeClassName="linkActive" className="footerLink" >Privacy Policy</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default FooterNav;