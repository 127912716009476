import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
//styles
import "../../../styles/webNav.css";

const WebNav = (props) => {
    let pathName = window.location.pathname;
    const linkRef = useRef(pathName);


    const [productsMenu, setProductsMenu] = useState(false);
    const [overlay, setOverlay] = useState(false);

    const handleProductsMenu = () => {
        setProductsMenu(prevState => !prevState);
        setIconRotate(prevState => !prevState)
        setOverlay(prevState => !prevState)
    }
    const handleLinkClick = () => {
        setProductsMenu(false);
        setIconRotate(false);
        setOverlay(false);
    }

    const [iconRotate, setIconRotate] = useState(false);
    



    return (
        <div className="webNav">
            <div className={overlay ? "activeOverlay" : "activeOverlay nodisplay"} onClick={handleLinkClick} />
            <div className="webNavLinksGroup">
                <div className="webNavListGroup">
                    <div className="webNavListItem">
                        <span className="webNavLink" onClick={handleProductsMenu}>Products <span className={iconRotate ? "iconRotate" : "arrowDD"} /></span>
                        <div className={productsMenu ? "dropDownLinkGroup showDropdown" : "dropDownLinkGroup"}>
                            <div className="megaMenuWrapper">
                                <div className="megaMenuItem">
                                    <div className="webMenuBlock">
                                        <div className="webMenuBlockHdr wc">
                                            <h4 className="webMenuHdngTxt">WEB CONFERENCING</h4>
                                        </div>
                                        <div className="webMenuBdy">
                                            <ul className="megaMenuUL">
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link ref={linkRef} to="/invc" className={`${pathName.match("/invc") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">inVC</span>
                                                        <small className="trailingTxt">WebRTC</small>
                                                    </Link>
                                                </li>

                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link to="/invcplus" className={`${pathName.match("/invcplus") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">inVC Plus</span>
                                                        <small className="trailingTxt">SIP &amp; WebRTC</small>
                                                    </Link>
                                                </li>
                                                
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link to="/invcpro" className={`${pathName.match("/invcpro") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">inVC Pro</span>
                                                        <small className="trailingTxt">H.323/SIP/WebRTC</small>
                                                    </Link>
                                                </li>
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link to="/combo-package" className={`${pathName.match("/combo-package") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">Combo Package</span>
                                                        <small className="trailingTxt">inVC + inVCPlus &amp; Pro</small>
                                                    </Link>
                                                </li>
                                                
                                                
                                            </ul>
                                        </div>
                                    </div>

                                </div>

                                {/* virtual clinic */}
                                <div className="megaMenuItem">
                                    <div className="webMenuBlock">
                                        <div className="webMenuBlockHdr vcx">
                                            <h4 className="webMenuHdngTxt">virtual clinic</h4>
                                        </div>
                                        <div className="webMenuBdy">
                                            <ul className="megaMenuUL">
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link ref={linkRef} to="/inclinix" className={`${pathName.match("/inclinix") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">inClinix</span>
                                                    </Link>
                                                </li>
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link to="/plans-pricing-inclinix" className={`${pathName.match("/plans-pricing-inclinix") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">Plans &amp; Pricing</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>


                                {/* virtual classroom */}
                                <div className="megaMenuItem">
                                    <div className="webMenuBlock">
                                        <div className="webMenuBlockHdr vcl">
                                            <h4 className="webMenuHdngTxt">virtual classroom</h4>
                                        </div>
                                        <div className="webMenuBdy">
                                            <ul className="megaMenuUL">
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link ref={linkRef} to="/inclass" className={`${pathName.match("/inclass") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">inClass</span>
                                                    </Link>
                                                </li>
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link to="/plans-pricing-inclass" className={`${pathName.match("/plans-pricing-inclass") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">Plans &amp; Pricing</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* webcasting */}
                                <div className="megaMenuItem">
                                    <div className="webMenuBlock">
                                        <div className="webMenuBlockHdr wct">
                                            <h4 className="webMenuHdngTxt">WEB casting</h4>
                                        </div>
                                        <div className="webMenuBdy">
                                            <ul className="megaMenuUL">
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link ref={linkRef} to="/incast" className={`${pathName.match("/incast") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">inCast</span>
                                                    </Link>
                                                </li>
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link to="/plans-pricing-incast" className={`${pathName.match("/incast") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">Plans &amp; Pricing</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                {/* invc API Suite */}
                                <div className="megaMenuItem">
                                    <div className="webMenuBlock">
                                        <div className="webMenuBlockHdr st">
                                            <h4 className="webMenuHdngTxt">invc api suite</h4>
                                        </div>
                                        <div className="webMenuBdy">
                                            <ul className="megaMenuUL">
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link ref={linkRef} to="/p2p-api" className={`${pathName.match("/p2p-api") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">P2P - API</span>
                                                    </Link>
                                                </li>
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link to="/plans-pricing-p2p-api" className={`${pathName.match("/plans-pricing-p2p-api") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">Plans &amp; Pricing</span>
                                                    </Link>
                                                </li>
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link ref={linkRef} to="/multipoint-api" className={`${pathName.match("/multipoint-api") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">Multipoint - API</span>
                                                    </Link>
                                                </li>
                                                <li className="megaMenuListItem" onClick={handleLinkClick}>
                                                    <Link to="/plans-pricing-multipoint-api" className={`${pathName.match("/plans-pricing-multipoint-api") ? "megaMenuLink activeMegaLink" : "megaMenuLink"}`}>
                                                        <span className="linkTxt">Plans &amp; Pricing</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                    <div className="webNavListItem" onClick={handleLinkClick}>
                        <Link to="/become-affiliate" title="Become Affiliate" className="webNavLink">Become Affiliate</Link>
                    </div>
                    <div className="webNavListItem" onClick={handleLinkClick}>
                        <Link to="/contact-us" title="contact us" className="webNavLink">Contact Us</Link>
                    </div>
                </div>
            </div>
            <div className="webNavBtnGroup">
                <a href="https://connect.instavc.com/sign-up" target="_blank" rel="noreferrer" className="webBtnLink" onClick={handleLinkClick}>
                    <button className="webnavBtn">
                        <span className="spanBtnTxt">sign up</span>
                    </button>
                </a>
            </div>
        </div>
    )
}

export default WebNav;