import React from 'react';
//components
import FooterNav from "./components/footerNav";
//css
import "../../styles/footerStyles.css";

export default function Footer() {
    return (
        <div className="footerContainer">
            <FooterNav />
        </div>
    );
}