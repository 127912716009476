import React from 'react';
import { useHistory } from 'react-router-dom';
import "./styles.css";
function PageNotFound() {
    let history = useHistory();

    const goToPreviousPath = () => {
        history.goBack()
    }
    return (
        <div className="pgNtfoundCntnr">
            <div className="page_404">
                <div className="page404Cntnr">
                    <div className="pageContnet">
                        <h1 className="text-center ">404</h1>
                    </div>
                    <div className="pageFrt">
                        <h3 className="h2">Look like you're lost</h3>
                        <p>the page you are looking for not avaible!</p>
                        <span className="link404" onClick={goToPreviousPath}>Go to Previous</span>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default PageNotFound;