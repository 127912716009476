import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
//components
import WebNav from "./components/webNav";
import ResSideNav from "./components/resNav";
import NavOverlay from "./components/navOverlay";
//styles
import "../../styles/headerStyles.css";

export default function Header() {
    const [headerScroll, setHeaderScroll] = useState(false);
    //const [logoColor, setLogoColor] = useState(false);
    const [navSlide, setNavSlide] = useState(false);
    const [navOverlay, setNavOverlay] = useState(false);

    const listenScrollEvent = event => {
        if (window.scrollY >= 64) {
            setHeaderScroll(true)
        } else {
            setHeaderScroll(false)
        }
    }
    // const listenLogoColor = event => {
    //     if (window.scrollY >= 64) {
    //         setLogoColor(true)
    //     } else {
    //         setLogoColor(true)
    //     }
    // }
    

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
    }, [])

    // useEffect(() => {
    //     window.addEventListener("scroll", listenLogoColor);
    // }, [])

    

    const handleSlide = () => {
        setNavSlide(prevState => !prevState);
        setNavOverlay(prevState => !prevState);
    }
    
    return (
        <div className={headerScroll ? "headerBar headerBarFilled" : "headerBar"}>
            <div className="appBar">
                <div className="mobHumburger" onClick={handleSlide}>
                    <span className="mobHumburgerline changeColor"></span>
                    <span className="mobHumburgerline changeColor"></span>
                    <span className="mobHumburgerline changeColor"></span>
                </div>
                <div className="brandingBar">
                    <Link to="/">
                        <img src="/images/logo/invc-small-48.png" className="logo logoWhite" alt="logo" />
                    </Link>
                </div>
                <div className="signUpBox">
                    <a href="https://connect.instavc.com/sign-up" className="mobBtnLink" target="_blannk" rel="noreferrals" >
                        <button className="mobnavBtn">
                            <span className="mobBtnTxt">sing up</span>
                        </button>
                    </a>
                </div>
                <WebNav />
                <div className={navSlide ? "topNarBar topNavSlide" : "topNarBar"}  >
                    <ResSideNav closeSlide={handleSlide} />
                </div>
                <NavOverlay closeOverlay={handleSlide} navOverlay={navOverlay} />
            </div>
        </div>
    );
}