import React, { Fragment} from 'react';
import { Route } from 'react-router-dom';
//styles
import "./styles.css";

const PageNotFound = ({ children }) => (
    <Fragment>
        <div className="pgNtfoundCntnr">
            {children}
        </div>
    </Fragment>
);


const PageNotFoundRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <PageNotFound>
                <Component {...matchProps} />
            </PageNotFound>
        )} />
    )
}

export default PageNotFoundRoute;