import React, {lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import PageNotFoundRoute from "./layouts/nopageLayouts/index";
import HomeRoute from "./layouts/homeLayout/index";

import PageNotFound from "./notfound/index";

import ScrollToTop from './modules/scrolls/scrollToTop';

const AsyncHome = lazy(() => import('./modules/home/index'));
const AsyncInVC = lazy(() => import('./modules/invc/index'));
const AsyncBuyInVC = lazy(() => import("./modules/buyInVC/index"));
const AsyncInVCPro = lazy(() => import('./modules/invcPro/index'));
const AsyncBuyInVCPro = lazy(() => import("./modules/buyInVCPro/index"));
const AsyncInVCPlus = lazy(() => import('./modules/invcPlus/index'));
const AsyncBuyInVCPlus = lazy(() => import("./modules/buyInVCPlus/index"));
const AsyncInClinix = lazy(() => import('./modules/inclinix/index'));
const AsyncBuyInClinx = lazy(() => import("./modules/buyInClinix/index"));
const AsyncInClass = lazy(() => import('./modules/inclass/index'));
const AsyncBuyInClass = lazy(() => import("./modules/buyInClass/index"));
const AsyncInCast = lazy(() => import('./modules/incast/index'));
const AsyncBuyInCast = lazy(() => import("./modules/buyInCast/index"));
const Asyncp2pAPI = lazy(() => import("./modules/p2pAPI/index"));
const AsyncBuyp2pAPI = lazy(() => import("./modules/buyp2pAPI/index"));
const AsyncMultipointAPI = lazy(() => import("./modules/multipointAPI/index"));
const AsyncBuyMultipointAPI = lazy(() => import("./modules/buyMultipointAPI/index"));
const AsyncContactUs = lazy(() => import('./modules/contactUs/index'));
const AsyncTermsOfService = lazy(() => import('./modules/termsOfServices/index'));
const AsyncAcceptableUsePolicy = lazy(() => import('./modules/acceptUsePolicy/index'));
const AsyncPrivacyPolicy = lazy(() => import('./modules/privacyPolicy/index'));
const AsyncBecomeAffiliate = lazy(() => import("./modules/becomeAffiliate/index"));
const AysncComboPlans = lazy(() => import("./modules/comboPlans/index"));

//Buy now components
const AsyncBuyCombo = lazy(() => import("./modules/comboBuy/index"));

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        
          <HomeRoute exact path="/" component={AsyncHome} />
          <HomeRoute exact path="/home" component={AsyncHome} />
          
          <HomeRoute exact path="/invc" component={AsyncInVC} />
          <HomeRoute exact path="/plans-pricing-invc" component={AsyncBuyInVC} />

          <HomeRoute exact path="/invcpro" component={AsyncInVCPro} />
          <HomeRoute exact path="/plans-pricing-invcpro" component={AsyncBuyInVCPro} />
          
          <HomeRoute exact path="/invcplus" component={AsyncInVCPlus} />
          <HomeRoute exact path="/plans-pricing-invcplus" component={AsyncBuyInVCPlus} />
          <HomeRoute exact path="/combo-package" component={AysncComboPlans} />
          <HomeRoute exact path="/combo-package/buy-combo" component={AsyncBuyCombo} />
          
          <HomeRoute exact path="/inclinix" component={AsyncInClinix} />
          <HomeRoute exact path="/plans-pricing-inclinix" component={AsyncBuyInClinx} />
          
          <HomeRoute exact path="/inclass" component={AsyncInClass} />
          <HomeRoute exact path="/plans-pricing-inclass" component={AsyncBuyInClass} />
          
          <HomeRoute exact path="/incast" component={AsyncInCast} />
          <HomeRoute exact path="/plans-pricing-incast" component={AsyncBuyInCast} />

          <HomeRoute exact path="/p2p-api" component={Asyncp2pAPI} />
          <HomeRoute exact path="/plans-pricing-p2p-api" component={AsyncBuyp2pAPI} />
          <HomeRoute exact path="/multipoint-api" component={AsyncMultipointAPI} />
          <HomeRoute exact path="/plans-pricing-multipoint-api" component={AsyncBuyMultipointAPI} />
          <HomeRoute exact path="/become-affiliate" component={AsyncBecomeAffiliate} />
          <HomeRoute exact path="/contact-us" component={AsyncContactUs} />

          <HomeRoute exact path="/terms-of-services" component={AsyncTermsOfService} />
          <HomeRoute exact path="/acceptable-use-policy" component={AsyncAcceptableUsePolicy} />
          <HomeRoute exact path="/privacy-policy" component={AsyncPrivacyPolicy} />
          <PageNotFoundRoute component={PageNotFound} />

          
        </Switch>
    </Router>
  );
}

export default App;
