import React, { Fragment, Suspense } from 'react';
import { Route } from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";
import Loader from './loader';

//styles
import "../../styles/mainStyles.css";


const HomeLayout = ({ children }) => (
    <Fragment>
        <div className="mainContainer">
            <Header />
            {children}
            <Footer />
        </div>
    </Fragment>
);


const HomeRoute = ({ component: Component, ...rest }) => {
    return (
        <Suspense fallback={<Loader />}>
            <Route {...rest} render={matchProps => (
                <HomeLayout>
                    <Component {...matchProps} />
                </HomeLayout>
            )} />
        </Suspense>
    )
}

export default HomeRoute;